import React, { Component } from "react";
import "./PirzadaSattar.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";

class PirzadaSattar extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
            <div className="pirzada-sattar">
                <div>
                    <div className="flex">

                        <h1>S. Pirzada Sattar M.D.</h1>
                        <img alt="Doctor Pirzada Sattar" src="../profile/drsattar-240x300.jpg" />
                    </div>

                    <p>Dr. Sattar founded Inroads to Recovery with two other mental health providers in 2004 to address the substance use disorders treatment needs in the Omaha Metro Area.</p>
                    <p>After completing his medical education from Dow Medical College in Pakistan, Dr. Sattar completed a training program in <strong>Psychoanalytic psychotherapy</strong> from the Michigan Institute of Psychoanalysis in 1999. He also completed a residency training program in <strong>General Psychiatry</strong> at Wayne State University School of Medicine in Detroit, Michigan. He then went on to complete a <strong>Forensic Psychiatry Fellowship</strong> training program from Harvard Medical School /Massachusetts mental Health Center and the University Of Massachusetts School Of Medicine. During this time he was honored as the Gaughan Fellow at Harvard Medical School. He then completed the <strong>Partner’s Addiction Psychiatry Fellowship</strong> program at Harvard Medical School/Massachusetts General Hospital in 2001.</p>
                    <p>After finishing his medical training, he started working as the <strong>Associate Director of the General Psychiatry Residency Training program</strong> at Creighton University/ University of Nebraska Schools of Medicine. He then started and served as the Director of the Addiction Psychiatry Fellowship training program at Creighton University and the University of Nebraska Medical Center in 2006 and continued to direct it until 2009. At this time, he left academic practice and started working full time as the <strong>President and Medical Director</strong> at the Inroads to Recovery residential and outpatient treatment programs. He also serves as the <strong>Medical Director of the Inpatient Psychiatric Treatment Program at Methodist Jennie Edmundson Hospital</strong> in Council Bluffs, IA.</p>
                    <p><strong>During his Academic career, he was awarded the following peer reviewed grants and awards</strong>:</p>
                    <ul>
                        <li>Robert Wood Johnson Fellowship in Developing Leadership in Reducing Substance Abuse
                            (2003)</li>
                        <li>Creighton University Heath Future Foundation Award</li>
                        <li>Academic Psychiatry Faculty Development award (2004)</li>
                        <li>NIH-NIDA Center of Excellence grant (2007)</li>
                        <li>5-year Career Development Award USDVA (2007)</li>
                        <li>Nebraska Tobacco Settlement Biomedical Research Collaboration Grant Award (2008).</li>
                    </ul>
                    <p><strong>He has also received numerous other honors and awards from the professional associations like:</strong></p>
                    <ul>
                        <li>The American Psychiatric Association</li>
                        <li>American Medical Association</li>
                        <li>American Association of Addiction Psychiatry</li>
                        <li>America Academy of Psychiatry and the Law</li>
                        <li>Association of Academic Psychiatry</li>
                        <li>United States Department of Veterans Affairs</li>
                        <li>He has been published in multiple peer reviewed journals including the New England Journal of Medicine and Academic Psychiatry and The Journal of the American Academy of Psychiatry and the law.”</li>
                    </ul>
                    <p><strong>Dr. Sattar’s published papers</strong></p>
                    <ul className="published-papers">
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15173552/" target="_blank"
                               rel="noreferrer">Sotalol-induced depression.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15024810/" target="_blank"
                               rel="noreferrer">Diagnosis and treatment of alcohol dependence in older alcoholics.</a>
                        </li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15898470/" target="_blank"
                               rel="noreferrer">Aripiprazole reduces alcohol use.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/17341539/" target="_blank"
                               rel="noreferrer">Bupropion therapy for pathological gambling.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15101571/" target="_blank"
                               rel="noreferrer">Aripiprazole possibly worsens psychosis.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/12271791/" target="_blank"
                               rel="noreferrer">Olanzapine-induced hyperventilation: case report.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/12594330/" target="_blank"
                               rel="noreferrer">A case of venlafaxine abuse.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15281415/" target="_blank" rel="noreferrer">Countering
                            countertransference, II: beyond evaluation to cross-examination.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/17052353/" target="_blank"
                               rel="noreferrer">Use of alcoholic beverages in VA medical centers.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/12452747/" target="_blank"
                               rel="noreferrer">Quetiapine therapy for posttraumatic stress disorder.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/16163401/" target="_blank"
                               rel="noreferrer">A primary care perspective of posttraumatic stress disorder for the
                            Department of Veterans Affairs.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/12920420/" target="_blank"
                               rel="noreferrer">Potential use of olanzapine in treatment of substance dependence
                            disorders.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/12927016/" target="_blank"
                               rel="noreferrer">Olanzapine for cocaine cravings and relapse prevention.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/16728764/" target="_blank"
                               rel="noreferrer">To commit or not to commit: the psychiatry resident as a variable in
                            involuntary commitment decisions.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/18196687/" target="_blank"
                               rel="noreferrer">Long-term adjunctive quetiapine may reduce substance use–a preliminary
                            retrospective study.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15644986/" target="_blank"
                               rel="noreferrer">Potential benefits of quetiapine in the treatment of substance dependence
                            disorders.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15479773/" target="_blank"
                               rel="noreferrer">Patient and physician attitudes to using medications with religiously
                            forbidden ingredients.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/17548783/" target="_blank"
                               rel="noreferrer">Impact of problem alcohol use on patient behavior and caregiver burden in
                            a geriatric assessment clinic.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/14766995/" target="_blank" rel="noreferrer">Inert
                            medication ingredients causing nonadherence due to religious beliefs.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/16820654/" target="_blank"
                               rel="noreferrer">Possible carbamazepine toxicity with terbinafine.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/14519043/" target="_blank" rel="noreferrer">Somnambulism
                            due to probable interaction of valproic acid and zolpidem.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/11821555/" target="_blank"
                               rel="noreferrer">When taking medications is a sin.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/11931370/" target="_blank" rel="noreferrer">Countering
                            countertransference: a forensic trainee’s dilemma.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/17242052/" target="_blank"
                               rel="noreferrer">Are medical students assigning proper global assessment of functioning
                            scores?</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/15507555/" target="_blank"
                               rel="noreferrer">Addiction training scale: pilot study of a self-report evaluation tool for
                            psychiatry residents.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/12243618/" target="_blank" rel="noreferrer">Visual
                            inspection of medications in preventing unexplained relapses.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/17649861/" target="_blank"
                               rel="noreferrer">Differences in the self-reported reasons for adolescent drug use reported
                            at admission vs. reported at discharge.</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/17882617/" target="_blank"
                               rel="noreferrer">Should alcohol be tolerated in the workplace?</a></li>
                        <li><a href="https://pubmed.ncbi.nlm.nih.gov/16141124/" target="_blank"
                               rel="noreferrer">New ACGME work-hour guidelines and their impact on current residency
                            training practices.</a></li>
                        <li className="docsum-citation full-citation"><a 
                                                                         href="https://pubmed.ncbi.nlm.nih.gov/12851447/"
                                                                         target="_blank" rel="noreferrer">Use of
                            adjunctive novel antipsychotics with depot haloperidol.</a></li>
                    </ul>







                </div>
              <Sidebar />
            </div>
        </Container>
    );
  }
}

export default PirzadaSattar;
