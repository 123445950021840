import React, { Component } from "react";
import "./Opiate-Use.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import ServicesCTA from "../servicesCTA";
import Opimg from "../../../img/opiate-use.png";


class OpiateUse extends Component {
  render() {
    return (

        <Container backgroundColor="#ffffff">
          <div className="services opiate-use">
            <div>

              <div className="OpImg">
                <img src={Opimg} alt="Get help for Opiod Addiction at Inroads to Recovery"/>
              </div>


              <h1>Opiate Use Disorder Treatment</h1>
              <p>We provide ‘medication-assisted treatment,’ for opiate use disorders using naltrexone, and
                buprenorphine (Suboxone).</p>
              <p><strong>We DO NOT provide methadone treatment, or stock any opiates at Inroads to Recovery.</strong>
              </p>
              <p>Most people cannot just walk away from opioid addiction. They need help to change their thinking,
                behavior, and environment. Unfortunately, “quitting cold turkey” has a poor success rate – fewer than 25
                percent of patients are able to remain abstinent for a full year. This is where medication-assisted
                treatment options like naltrexone, and Suboxone benefit patients in staying sober while reducing the
                side effects of withdrawal and curbing cravings which can lead to relapse.</p>
              <h1>Medication-Assisted Treatment</h1>
              <p>Medication-assisted treatment for opioid dependence can include the use of buprenorphine (Suboxone) and
                to complement the effects of this medication, education, counseling and other support measures that
                focus on the behavioral aspects of opioid addiction are provided. This medication can allow one to
                regain a normal state of mind – free of withdrawal, cravings and the drug-induced highs and lows of
                addiction. Medication-assisted treatments for opioid addiction and dependence is much like using
                medication to treat other chronic illnesses such as heart disease, asthma or diabetes. Taking medication
                for opioid addiction is not the same as substituting one addictive drug for another.</p>
              <h1>Naltrexone</h1>
              <p>Naltrexone is an opioid blocker that is also useful in the treatment of opioid addiction. Naltrexone
                blocks the euphoric and pain-relieving effects of heroin and most other opioids. This type of
                medication-assisted treatment does not have addictive properties, does not produce physical dependence,
                and tolerance does not develop. Unlike methadone or Suboxone, it has several disadvantages. It does not
                suppress withdrawal or cravings. Therefore, many patients are not motivated enough to take it on a
                regular basis. It cannot be started until a patient is off of all opioids for at least two weeks, though
                many patients are unable to maintain abstinence during that waiting period. Also, once patients have
                started on naltrexone the risk of overdose death is increased if relapse does occur.</p>
              <h1>Buprenorphine / Subutex / Suboxone</h1>
              <p>In 2002, the FDA approved the use of the unique opioid buprenorphine (Subutex, Suboxone) for the
                treatment of opioid addiction in the U.S. Buprenorphine has numerous advantages over methadone and
                naltrexone. As a medication-assisted treatment, it suppresses withdrawal symptoms and cravings for
                opioids, does not cause euphoria in the opioid-dependent patient, and it blocks the effects of the other
                (problem) opioids for at least 24 hours. Success rates, as measured by retention in treatment and
                one-year sobriety, have been reported as high as 40 to 60 percent in some studies. Treatment does not
                require participation in a highly-regulated federal program such as a methadone clinic. Since
                buprenorphine does not cause euphoria in patients with opioid addiction, its abuse potential is
                substantially lower than methadone.</p>
              <h1>What Is Suboxone and How Does it Work?</h1>
              <p>Medication-assisted treatment for opioid dependence can include the use of buprenorphine (Suboxone) and
                to complement the effects of this medication, education, counseling and other support measures that
                focus on the behavioral aspects of opioid addiction are provided. This medication can allow one to
                regain a normal state of mind – free of withdrawal, cravings and the drug-induced highs and lows of
                addiction. Medication-assisted treatments for opioid addiction and dependence is much like using
                medication to treat other chronic illnesses such as heart disease, asthma or diabetes. Taking medication
                for opioid addiction is not the same as substituting one addictive drug for another.</p>
              <h1>What Is a ‘Partial Opioid Agonist’?</h1>
              <p>A ‘partial opioid agonist’ such as buprenorphine is an opioid that produces less of an effect than a
                full opioid when it attaches to an opioid receptor in the brain. Oxycodone, hydrocodone, morphine,
                heroin and methadone are examples of ‘full opioid agonists.’ For the sake of simplicity from this point
                on we will refer to buprenorphine (Suboxone) as a ‘partial opioid’ and all the problem opioids like
                oxycodone and heroin as ‘full opioids.’
                When a ‘partial opioid’ like Suboxone is taken, the person may feel a very slight pleasurable sensation,
                but most people report that they just feel “normal” or “more energized” during medication-assisted
                treatment. If they are having pain they will notice some partial pain relief.</p>

              <p>People who are opioid dependent do not get a euphoric effect or feel high when they take buprenorphine
                properly. Buprenorphine tricks the brain into thinking that a full opioid like oxycodone or heroin is in
                the body and this suppresses the withdrawal symptoms and cravings associated with that problem
                opioid.</p>

              <p>Buprenorphine is a long-acting form of medicated-assisted treatment, meaning that it gets ‘stuck’ in
                the brain’s opiate receptors for about 24-72 hours. When buprenorphine is stuck in the receptor, the
                problem ‘full opioids’ can’t get in. This gives the person with opioid addiction a 24-72-hour reprieve
                each time a dose of Suboxone is taken. If a full opioid is taken within 24-72 hours of Suboxone, then
                the patient will quickly discover that the full opioid is not working – they will not get high and will
                not get pain relief (if pain was the reason it was taken). This 24-72 hour reprieve gives the patient
                time to reconsider the wisdom of relapsing with a problem opioid while undergoing medication-assisted
                treatment.</p>

              <p>Another benefit of buprenorphine in treating opioid addiction is something called the ‘ceiling effect.’
                This means that taking more Suboxone than prescribed does not result in a full opioid effect. Taking
                extra Suboxone will not get the patient high. This is a distinct advantage over methadone. Patients can
                get high on methadone because it is a full opioid. The ceiling effect also helps if buprenorphine is
                taken in an overdose – there is less suppression of breathing than that resulting from a full
                opioid.</p>

              <ServicesCTA/>
            </div>
            <Sidebar/>
          </div>
        </Container>
    )

  };
}

export default OpiateUse;
