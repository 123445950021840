import React, { Component } from "react";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import ServicesCTA from "../servicesCTA";

class Phencyclidine extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
          <div className="services phencyclidine">
            <div>
              <h1>Phencyclidine Addiction</h1>
              <p>
                Phencyclidine (PCP) (fen-SAI-kluh-deen), also known as Angel Dust, is a dissociative anesthetic with a
                history of recreational misuse. Originally developed in the 1950s as an intravenous anesthetic, its
                medical use has since been discontinued due to postoperative delirium. Inroads to Recovery offers
                tailored care for individuals struggling with PCP addiction. Our personalized approach addresses the
                effects of PCP misuse, which can include feelings of detachment, anxiety, memory loss, and altered
                sensory perceptions. Given its potential for abuse, PCP is classified as a Schedule II drug under the
                U.S. Controlled Substances Act. Our treatment provides insights into the effects and signs of
                phencyclidine use disorder, offering support for those seeking help with PCP addiction. </p>
              <h2>
                <strong>What is PCP?</strong>
              </h2>
              <p>
                Discovered in 1926, PCP was initially used as a general anesthetic and marketed in the United States
                under the brand name Sernyl. However, thanks to related post-op hallucinations and feelings of unease
                (aka dysphoria), it was discontinued for human use in 1967, and legal use has been limited to veterinary
                applications ever since. In the ‘60s, however, PCP emerged as a popular street drug illegally
                manufactured in laboratories. And by 1970, its recreational use was widespread. Street names for PCP
                include angel dust, ozone, wack, and embalming fluid. </p>
              <p>
                Although PCP comes in several forms, such as tablets and capsules, it’s most often used in liquid or
                powder forms. PCP can be injected, swallowed, and snorted, and it’s sometimes smoked after being
                sprinkled on leafy substances such as tobacco, parsley, and marijuana
                (https://americanaddictioncenters.org/marijuana-rehab). When PCP is smoked with marijuana, the
                combination is sometimes called killer joints, super grass, and fry.</p>
              <p>
                <strong>Short-Term Effects of PCP </strong>
              </p>
              <p>
                Studies suggest that dissociative drugs such as PCP disrupt the action of a
                brain chemical called glutamate, which plays a role in emotions, pain
                perception, and cognition. PCP may also alter the actions of dopamine, and
                the effect likely creates the “rush” people feel from various recreational
                drugs. The short-term effects of dissociative drugs such as PCP are typically
                dose-dependent. According to the <a
                  href="https://nida.nih.gov/publications/research-reports/hallucinogens-dissociative-drugs/what-are-effects-common-dissociative-drugs-brain-body"
                  target="_blank" rel="noopener noreferrer">
                National Institute on Drug Abuse (NIDA)</a>, low to moderate doses can deliver
                effects such as:
              </p>
              <ul>
                <li>Changes in sensory perceptions (e.g., body image, sight, sound, shapes, time).</li>
                <li>Feelings of detachment from oneself and the environment.</li>
                <li>Feeling disoriented and confused.</li>
                <li>Hallucinations.</li>
                <li>Increased heart rate, breathing, body temperature, and blood pressure.</li>
                <li>Face redness and sweating.</li>
                <li>Dizziness, nausea, vomiting.</li>
                <li>Numbness in the hands and feet.</li>
                <li>Loss of coordination.</li>
                <li>Difficulty moving.</li>
              </ul>
              <p>Higher doses of PCP can sometimes result in PCP overdose as well as various other adverse short-term
                effects that can include (but not limited to): </p>
              <ul>
                <li>Psychotic symptoms such as hallucinations, delusions, and disordered thought.</li>
                <li>Fear, panic, anxiety, paranoia, exaggerated strength, aggression, and feelings of invulnerability.
                </li>
                <li>Violent behaviors.</li>
                <li>Dangerous changes in blood pressure, heart rate, body temperature, and breathing.</li>
                <li>Quick up and down eye movements.</li>
                <li>Severe muscle spasm.</li>
                <li>Seizures.</li>
                <li>Coma.</li>
                <li>Death.</li>
              </ul>
              <p>
                <strong>Mixing PCP with Other Drugs </strong>
              </p>
              <p>
                PCP is often used in addition to other substances, and such combinations may be particularly dangerous.
                When paired with depressants such as <a href="https://americanaddictioncenters.org/benzodiazepine"
                                                        target="_blank"
                                                        rel="noopener noreferrer">benzodiazepines</a> (e.g., <a
                  href="https://americanaddictioncenters.org/xanax-treatment" target="_blank"
                  rel="noopener noreferrer">Xanax</a>) or <a
                  href="https://americanaddictioncenters.org/alcoholism-treatment" target="_blank"
                  rel="noopener noreferrer">alcohol</a>,
              </p>
              <p>
                At Inroads to Recovery, we understand the challenges associated with addiction and are committed to
                providing personalized care and support. Our dedicated team is here to help individuals struggling with
                PCP addiction, offering tailored treatment to address their specific needs and guide them on the path to
                recovery. You are not alone in this journey, and we are here to support you every step of the way.
              </p>
              <ServicesCTA/>
            </div>
            <Sidebar/>
          </div>
        </Container>
    );
  }
}

export default Phencyclidine;
