import React from "react";
import "./Container.css";

const Container = ({ children, backgroundColor }) => {
  return (
      <div className="component-background" style={{ backgroundColor: backgroundColor }}>
        <div className="component-container">
          {children}
        </div>
      </div>
  );
}

export default Container;
