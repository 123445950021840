import React, { Component } from "react";
import "./Our-Team.css";
import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";
import { associates } from "./teamData.js";
import { Link } from "react-router-dom";


// Associate Component
function Associate({ associate }) {
    return (
        <div className={`associate associate-${associate.id}`}>
            <div>
                {associate.image && <img className="profile-img" src={associate.image} alt={associate.name} />}
            </div>
            <div>
                <h2 className="name">{associate.name}</h2>
                <h3 className="title">{associate.title}</h3>
                {associate.bio.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
                {associate.biolink && <Link to={associate.biolink}>Read More</Link>}
            </div>

        </div>
    );
}



class OurTeam extends Component {
    render() {
        return (
            <Container backgroundColor="#ffffff">
                <div className="page-sidebar our-team">
                    <div>
                        {associates.map(associate =>
                            <Associate key={associate.id} associate={associate} />
                        )}
                    </div>
                    <Sidebar />
                </div>

            </Container>
        );
    }
}

export default OurTeam;
