import React, { Component } from "react";
import "./Footer.css";
import Container from "../Container";
import {PHONE_NUMBER, FAX_NUMBER, ADDRESS, IOP_HOURS, BUSINESS_HOURS} from "../../constants";
import Carf from "../../img/carf-300x300.png";
import BBB from "../../img/bbb-logo-300x153.png";
class Footer extends Component {

    renderIOPHours(hours) {
        if (hours === 'N/A') {
            return <span className="na">{hours}</span>;
        } else {
            return <span>{hours}</span>;
        }
    }

    render() {
          return (
              <Container backgroundColor="#1a1c20">
                  <div className="footer">
                  <div className="infoblocks footertxt">

                    <div className="contact-footer">
                        <h1>Contact</h1>
                        <p>Phone: {PHONE_NUMBER}</p>
                        <p>Fax: {FAX_NUMBER}</p>
                        <p>{ADDRESS}</p>
                    </div>

                      <div>
                          <h1>Hours of Operation</h1>
                          <div className="hours">
                              <div><span>Monday:</span> <span>{BUSINESS_HOURS.Monday}</span></div>
                              <div><span>Tuesday:</span> <span>{BUSINESS_HOURS.Tuesday}</span></div>
                              <div><span>Wednesday:</span><span>{BUSINESS_HOURS.Wednesday}</span></div>
                              <div><span>Thursday:</span> <span>{BUSINESS_HOURS.Thursday}</span></div>
                              <div><span>Friday:</span> <span>{BUSINESS_HOURS.Friday}</span></div>
                          </div>
                      </div>


                      <div className="iop-hours-wrapper">
                          <h1>Intensive Outpatient Program (IOP) Hours</h1>
                          <div className="iop-hours">
                              <p>Weekday Hours</p>
                              <div><span>Monday:</span> {this.renderIOPHours(IOP_HOURS.Monday)}</div>
                              <div><span>Tuesday:</span> {this.renderIOPHours(IOP_HOURS.Tuesday)}</div>
                              <div><span>Wednesday:</span> {this.renderIOPHours(IOP_HOURS.Wednesday)}</div>
                              <div><span>Thursday:</span> {this.renderIOPHours(IOP_HOURS.Thursday)}</div>
                              <p>Weekend Hours</p>
                              <div><span>Friday:</span> {this.renderIOPHours(IOP_HOURS.Friday)}</div>
                              <div><span>Saturday:</span> {this.renderIOPHours(IOP_HOURS.Saturday)}</div>
                              <div><span>Sunday:</span> {this.renderIOPHours(IOP_HOURS.Sunday)}</div>

                          </div>
                      </div>

                      <div>
                      <h1>Patient Rights | Privacy Policy</h1>
                          <p>Declaration of Patient Rights</p>
                          <p>Notice of Privacy Practices</p>
                          <p>Website Privacy Policy</p>
                      </div>
                      </div>

              <div className="infoblocks logos">
                  <div><img alt="CARF Accredited" src={Carf} /></div>
                  <div><img alt="Better Business Bureau Accredited" src={BBB} /></div>

              </div>

                  <div className="disclaimer">
                      <p>Any use of this website or the information contained in the website is at your own risk. We will not be responsible for the consequences of your decision to utilize the information contained in this website. The medical information provided in this site is for educational purposes only, it is not intended nor implied to be a substitute for professional medical advice. Always consult your physician or healthcare provider prior to starting any new treatment or with any questions you may have regarding a medical condition.</p>
                  </div>
                  </div>
              </Container>
          );

    }
}

export default Footer;
