import React, { Component } from "react";
import Container from "../../components/Container";
import Sidebar from "../../components/Sidebar";
import "./Testimonials.css";

class Testimonials extends Component {
  render() {
    return (
            <Container backgroundColor="#ffffff">

              <div className="testimonials">
                <div className="testimonialContent">
                  <h1>Testimonials</h1>
                  <p>"I am so glad there are places like Inroads to Recovery, you change lives more than you know. The staff is so caring."<br /> &mdash; M.K.  </p>
                  <p>"I found that the staff were all amazing, very giving and their knowledge was great. Comfort and food was good- Thank you once again."<br /> &mdash; T.J.</p>
                  <p>"Inroads was truly instrumental in creating a recovery environment. They were open and caring while being honest. The depth of treatment is appropriate without being negative. They don’t miss a thing in our recovery journey and really helps people find the problem and solutions. The opportunity to share, heal and grow is present in every session. Their admissions coordinator has amazing communication skills and is very organized in setting up treatment. The recovery materials were appropriate and informative. They were great tool for ongoing process of recovery. I continued my journey from residential to the IOP. I am impressed by the IOP program and very happy with every level of care I received while being a patient at Inroads to  recovery."<br /> &mdash; Anonymous</p>
                  <p>"Thank you for taking a chance on me and giving me my life back."<br /> &mdash; B.W.</p>
                </div>

                <Sidebar />

              </div>

            </Container>
        )
  }
}

export default Testimonials;
