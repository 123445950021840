import React, { Component } from "react";
import "./Substance-Abuse.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import ServicesCTA from "../servicesCTA";

class SubstanceAbuse extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
            <div className="services substance-abuse">
                <div>
                    <h1>Substance Abuse</h1>
                  <p>We provide standardized and clinical assessment based substance use evaluations that include Substance Abuse Subtle Screening Inventory (SASSI) as well as other standardized testing as might be necessary. These evaluations meet the criteria and the standards for all court ordered substance use evaluations. These evaluations are generally used to assess the appropriate level of treatment for the individuals struggling with substance use disorders.</p>
                    <ServicesCTA/>
                </div>
                <Sidebar />
            </div>
        </Container>
    );
  }
}

export default SubstanceAbuse;
