import React, { Component } from "react";
import "./Residential-Program.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import Arrivial from "../../../img/inroads-art.png";
import ServicesCTA from "../servicesCTA";

class ResidentialProgram extends Component {
    render() {
        return (
            <Container backgroundColor="#ffffff">
                <div className="services residential-program">
                    <div>


                        <h1>Residential Program Overview</h1>
                        <img className="arrivalImg" src={Arrivial} alt="Arriving"/>
                        <p>The Residential Program operates seven days a week, 24 hours per day. All residents follow a
                            posted schedule of activities. Check-in time for the treatment center is No later than 11am,
                            unless discussed with the Residential Coordinator. Check-out time is to be determined with
                            staff.</p>
                        <p>Our physician and physician assistants oversee your medical and treatment plan with your
                            input. You will
                            not see the doctor every day, but your primary therapist and nurse will be in continual
                            contact with the
                            doctor regarding your recovery and treatment plan. If a medical emergency arises, 911 will
                            be called and you
                            will be taken to the nearest emergency room.</p>


                        <h1>What to expect when you first arrive</h1>

                        <p>You will be greeted by the front desk and fill out all paperwork necessary for residential
                            admission. You
                            will wait to see a provider and once you are seen a medical staff will escort you to our
                            residential side,
                            where the medical staff will take your vitals, check in, inventory your items brought in,
                            proceed to dry
                            your clothing for 60 minutes, then show you to your room, you will be given a few lab tests.
                            After this
                            process, the medical staff will show you around and introduce you to everyone. You’ll meet
                            with the intake
                            counselor to complete your residential admission assessment (this is the form of the ASI
                            “Adult Severity
                            Index” or BSAP “Biopsychosocial Assessment”.) Our residential admissions coordinator will
                            work with your
                            insurance for your treatment’s preauthorization benefits.</p>

                        <h1>Items to bring</h1>
                        <p>The compassionate and caring team at Inroads does its utmost best to provide you with the
                            resources you and your family need to prepare for your inpatient stay at our facility.</p>
                        <p>We understand that knowing what to expect when beginning your recovery journey for a
                            substance use treatment can help make the process seem less daunting. Patients seeking care
                            through our inpatient program should bring:</p>
                        <ul>
                            <li>A week’s supply of casual clothes, such as jeans, T-shirts, sweats, shorts (if they are
                                not too short).
                            </li>
                            <li>30-day supply of personal hygiene items, including hair brushes or combs, deodorant,
                                shampoo, conditioner, toothbrush, toothpaste, shaving equipment, menstrual products,
                                etc.
                            </li>
                            <li>Shower shoes</li>
                            <li>All medications you are currently taking</li>
                        </ul>
                        <p>In addition to the general packing list, patients at our facility may bring:</p>

                        <ul>
                            <li> A personal fan and clock radio</li>
                            <li>A curling iron or hair dryer</li>
                            <li>Patients age 21 and older can bring store-bought sealed pack of cigarettes</li>
                            <li>Envelopes, stationery, and stamps</li>
                        </ul>

                        <p>We provide all bedding, pillows, and towels for every patient at Inroads to Recovery.
                            Washers, dryers, and laundry soap are also available to our patients at no additional cost.
                            Bedroom placement is determined by the staff and subject to change at any time for
                            incoming/outgoing residents. Residents are not allowed to move from their assigned rooms
                            without permission from medical staff prior to moving.</p>

                        <h1>What not to bring</h1>
                        <p>The comfort and safety of every patient who seeks inpatient care at Inroads to Recovery is
                            our top priority. While receiving inpatient care at our facility, there are certain items
                            that patients should leave at home, including:</p>

                        <ul>
                            <li>Any clothing with logos promoting alcohol, sex, drugs, gambling, or gangs</li>
                            <li>Shorts or skirts shorter than two inches above the knee, body shirts, halter tops, tank
                                tops, strapless shirts or dresses, tube tops, low-cut shirts, midriff shirts,
                                see-through shirts, low-cut jeans, and jeans and jeggings with see-through or sheer
                                panels above the knee
                            </li>
                            <li>Aerosol cans, perfumes, body sprays, colognes, or products that contain alcohol within
                                the first three ingredients
                            </li>
                            <li>Pillows, blankets, towels, and stuffed animals</li>
                            <li>E-cigarettes, vaping devices, chewing tobacco and rolling or loose tobacco</li>
                            <li>Cellphones and other electronic devices, such as smartwatches, computers, iPods, MP3
                                players, stereos, CD players, TVs, DVD players, cameras, and camcorders
                            </li>
                            <li>Nail polish and nail polish remover</li>
                            <li>Cards, dice, and other gambling-related items</li>
                            <li>Huffable products, such as Wite-Out or Sharpie markers</li>
                            <li>Handheld games and cartridges</li>
                            <li>Pictures with glass in the frame</li>
                            <li>Jewelry</li>
                            <li>Outside food or drink</li>
                        </ul>

                        <div className="callOut">
                            <h1>Important Notes</h1>
                            <p><strong>Valuables/Personal Belongings:</strong> The staff at Inroads to Recovery will
                                safely store any items that are deemed inappropriate and will return them to you or your
                                loved one upon discharge, but we destroy any illegal or contraband items. If possible,
                                it is best to leave any valuable or important items at home while receiving inpatient
                                care at our facility. Inroads is not responsible for any lost or missing items. Please
                                limit the amount of money, credit cards, jewelry, or other valuable items you bring into
                                the facility. All items brought into the facility after admission must be checked in and
                                inventoried with staff.</p>
                            <p><strong>Pets:</strong> Pets are not allowed on the grounds.</p>
                            <p><strong>Vehicles:</strong> Residents are not allowed to bring personal vehicles, unless
                                approved by the Residential Coordinator or Office Admin.</p>
                        </div>


                        <h1>Family / Visitors</h1>
                        <p>When the primary therapist and clinical team believe the resident is ready, he/she will be
                            allowed to have visitors during normal visiting hours, based on his/her treatment goals.</p>
                        <p>The following information will help you become familiar with our visiting hours at the
                            facility.</p>

                        <ul>
                            <li>A therapeutic visitor form must be filled out every week and turned into your therapist
                                by Thursday at noon. If this is not filled out, your visitors will not be approved and
                                will be asked to leave.
                            </li>
                            <li>Visiting hours are between 1:00pm-3:00pm Sunday.</li>
                            <li>Visitors are limited to two persons per visit, unless other arrangements have been
                                authorized by the therapists.
                            </li>
                            <li>All minor visitors (i.e. 18 years and younger) must be in the care of their legal
                                guardian while on the property.
                            </li>
                            <li>All visitors must adhere to the Inroads to Recovery visitors policy as stated in the
                                residential handbook.
                            </li>
                            <li>Please note that a patient must have 18 months of sobriety after leaving treatment at
                                Inroads prior to visiting a resident at Inroads to Recovery.
                            </li>
                        </ul>
                        <p><strong>Item Drop Offs:</strong> If any visitor wishes to bring or drop off an item to a
                            resident, it must be dropped off at the reception area on the outpatient side of the
                            building (southwest door entrance) during normal business hours: Monday-Thursday
                            8:30am-4:30pm and Friday 8:30am-2:30pm ONLY.</p>
                        <p>In an effort to optimize the residents bonding time we <strong>absolutely do not allow any
                            drop offs on the weekends</strong>. Items may be brought ONLY during normal visiting hours
                            on Saturday’s and Sunday’s, and must be walked back to the nurse’s station to await
                            inspection by med staff. Residents are not allowed to take any items directly from visitors.
                        </p>
                        <p>Items should be sealed and clearly marked with the resident’s name, all items are subject to
                            inspection in an effort to enhance the collective safety of all the patients.</p>
                        <p><strong>Mail:</strong> We encourage Family and friends to send residents mail at any time. We
                            believe that words of encouragement are a huge part of the recovery process. All mail will
                            be opened with staff in front of the patient, prior to the patient receiving the mail.</p>

                        <h1 className="hand"> A Special Message to Friends and Family</h1>
                        <div className="callOut">
                            <p><strong>Thank you for entrusting your loved one to our care.</strong></p>

                            <p>Participating in treatment is often an overwhelming experience for your loved one so he
                                or she will require a great deal of support from you while in treatment. Your loved one
                                will be given a great deal of care while in our treatment facility. At Inroads to
                                Recovery all clients participate in an educational and evidence based holistic approach
                                to treatment, designed to assist them in increasing their understanding of addiction and
                                to facilitate their personal journey toward recovery. Our staff helps clients identify
                                triggers both within themselves and within their environment and we work with them to
                                develop appropriate coping skills to prevent relapse from occurring.</p>
                            <p>
                                We would like to take this opportunity to provide you with important information about
                                our treatment facility. Upon admission, patients receive a psychiatric evaluation and
                                assessment. Following these assessments, our team will determine the most appropriate
                                level of care and begin developing a treatment plan.</p>

                            <p>We believe that having family (as well as other sober supports) participate in treatment
                                leads to the best outcomes. Here are some of the resources that you may find
                                helpful:</p>
                            <p><strong>Online Resources</strong></p>
                            <ul>
                                <li><a href="https://naminebraska.org/" target="_blank"
                                       rel="noopener noreferrer">naminebraska.org</a></li>
                                <li><a href="https://www.nimh.nih.gov/index.shtml" target="_blank"
                                       rel="noopener noreferrer">nimh.nih.gov</a></li>
                                <li><a href="https://www.niaaa.nih.gov/" target="_blank"
                                       rel="noopener noreferrer">niaaa.nih.gov</a></li>
                                <li><a href="https://www.drugabuse.gov/" target="_blank"
                                       rel="noopener noreferrer">drugabuse.gov</a></li>
                                <li><a href="https://www.psychiatry.org/" target="_blank"
                                       rel="noopener noreferrer">psychiatry.org</a></li>
                                <li><a href="https://www.aaap.org/" target="_blank"
                                       rel="noopener noreferrer">aaap.org</a></li>
                            </ul>
                            <p><strong>Telephone Resources</strong></p>
                            <ul>
                                <li><strong>Suicide Prevention:</strong> <a href="tel:8002738255">(800) 273-TALK / (800)
                                    273-8255</a></li>
                                <li><strong>Suicide and Crisis Lifeline:</strong> <a href="tel:988">988</a>
                                </li>
                                <li><strong>Nebraska Family Helpline:</strong> <a href="tel:8888668660">(888)
                                    866-8660</a></li>
                                <li><strong>Economic Assistance:</strong> <a href="tel:8003834278">(800) 383-4278</a>
                                </li>
                                <li><strong>Medicaid Assistance:</strong> <a href="tel:8556327633">(855) 632-7633</a>
                                </li>
                                <li><strong>Domestic Abuse Hotline:</strong> <a href="tel:8005233666">(800) 523-3666</a>
                                </li>
                                <li><strong>Child and Adult Abuse:</strong> <a href="tel:8006521999">(800) 652-1999</a>
                                </li>
                                <li><strong>Poison Control:</strong> <a href="tel:8002221222">(800) 222-1222</a>
                                </li>
                            </ul>

                            <p> We expect that every person entering treatment will possess varying degrees of
                                motivation. As treatment becomes more difficult, that motivation may be tested. As a
                                result, there may be a time when he or she will call asking to be picked up or to be
                                allowed to leave because things have become difficult to manage. We are here as a
                                resource to assist you and provide reassurance during these difficult moments. It is
                                important that everyone entering treatment remains involved in activities and completes
                                goals successfully. If a client is attempting to leave treatment before they have
                                completed it can be very detrimental to their long-term recovery journey. Always
                                remember the reasons that your loved one required treatment and support them in
                                completing treatment.</p>

                            <p>Once again we understand your concern for your family member and we hope to validate your
                                trust in us to provide treatment and assist in the journey of recovery. Thank you for
                                choosing Inroads to Recovery.</p>
                        </div>
                        <ServicesCTA/>
                    </div>
                    <Sidebar/>
                </div>
            </Container>
        );
    }
}

export default ResidentialProgram;
