import React, { Component } from "react";
import "./Alcohol-Use-Disorder.css";
import Container from "../../../components/Container";
import Sidebar from "../../../components/Sidebar";
import ServicesCTA from "../servicesCTA";

class AlcoholUseDisorder extends Component {
  render() {
    return (
        <Container backgroundColor="#ffffff">
            <div className="services alcohol-use-disorder">
                <div>
                    <h1>Alcohol Use Disorder Treatment</h1>
                  <p>Alcoholism is a treatable disease and many treatment programs and approaches are available to support alcoholics who have decided to get help, but no medical cure is available.</p>
                  <p>Regardless of how someone is diagnosed as alcohol dependent or how they came to realize they have a serious drinking problem, the first step to treatment is a sincere desire to get help. Alcoholics who are pressured into treatment by social pressures or forced to quit by circumstances have a harder time in succeeding in the long run.</p>
<p>Even many alcoholics who seek treatment on their own volition have at least one relapse before they obtain long-term sobriety. For those alcoholics who have a strong motivation to quit, a relapse can be just a bump in the road to recovery, but for those less committed, it can be an excuse to return to a full blown relapse and a drinking lifestyle.</p>
                  <h1>Alcohol Withdrawal Treatment</h1>
                  <p>Almost all alcoholics who have been heavy drinkers, will experience some level of withdrawal symptoms when they suddenly stop drinking. These symptoms can range from mild shakes and discomfort to life-threatening delirium tremens — which can include confusion, hallucinations, convulsions, autonomic instability, and death. Long-time, heavy drinkers who decide to quit drinking should seek medical assistance first.</p>
<p>Detoxification treatment includes abstinence from alcohol in a controlled environment and close monitoring of vital signs and any withdrawal symptoms.</p>
                  <p>In some cases, detox treatment can also include the temporary administration of benzodiazepines (tranquilizers such as Valium, Librium, Ativan or Serax). The tranquilizers help reduce the shakes and other unpleasant symptoms during early alcohol withdrawal and help to reduce the risk of seizures.</p>
                  <h1>Alcohol Rehab and Treatment</h1>
                  <p>The process of detoxification from alcohol takes three to seven days, after which the alcoholic’s dependency on alcohol is primarily psychological, rather than physical or chemical. The goal after detox is to prevent a relapse of excessive drinking.</p>
                  <p>For many addicts and alcoholics, getting clean and sober is just the first step in a process to try to rebuild their lives. We try to teach them the skills to return to a happy, productive life.</p>
                  <h1>Pharmaceutical Treatment for Alcoholism</h1>
                  <p>Although there is no “magic pill” that will cure alcoholism, there are medications approved by the Food and Drug Administration that are used to help people who have stopped drinking to remain sober. Currently, three medications are approved in the U.S. for the treatment of alcoholism.</p>
                  <p>Antabuse (disulfiram) works as a deterrent against drinking by making the person sick if they consume any alcohol. Naltrexone (Revia) blocks the effects of alcohol in the brain and reduces alcohol craving. Acamprosate (Campral) relieves the distress and discomfort alcoholics experience when they stop drinking.</p>
                  <p>Again, pharmaceutical treatments work best when the alcoholic has a sincere desire to quit. For those who are committed to remaining sober, medications can give them the extra help they need to avoid relapse.</p>
<h1>Alcoholism Support Groups</h1>
                  <p>One of the oldest and most well-known “treatments” for alcoholism is Alcoholics Anonymous (AA), a fellowship of men and women who share their experience, strength, and hope with each other to solve their common problem and help others to recover from alcoholism. Participation in A.A. is free.</p>
                  <p>Scientific research has shown that participation in support groups, combined with other treatment efforts, is more effective in helping alcoholics remain sober than participating in any treatment by itself. For those who do not prefer the 12-step approach, there are other secular support groups available like Rational Recovery.</p>
                  <p>The fellowship and accountability that mutual support groups provide have helped many with a desire to quit drinking establish and maintain a clean and sober lifestyle.</p>
                    <ServicesCTA/>
                </div>
                <Sidebar />
            </div>
        </Container>
    );
  }
}

export default AlcoholUseDisorder;
