import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFloating, offset, shift, flip, autoUpdate, useInteractions, useHover, useClick, useDismiss } from '@floating-ui/react';
import logo from "../../img/inroads-logo-314.png";
import './Nav.css';

const DropdownMenu = ({ items, isOpen, setIsOpen }) => {
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [offset(5), flip(), shift()],
        whileElementsMounted: autoUpdate
    });

    const hover = useHover(context);
    const click = useClick(context);
    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
        click,
        dismiss
    ]);

    return (
        <div className="dropdown-container" ref={refs.setReference} {...getReferenceProps()}>
            {isOpen && (
                <div
                    className="dropdown-menu"
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                >
                    {items.map((item, index) => (
                        <Link
                            key={index}
                            to={item.link}
                            className="dropdown-item"
                        >
                            {item.name}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

const Nav = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);

    const menuItems = [
        { name: 'Home', link: '/' },
        {
            name: 'About Us',
            link: '/about',
            submenu: [
                { name: 'Our Team', link: '/Our-Team' },
                { name: 'FAQ', link: '/Frequently-Asked-Questions' }
            ]
        },
        { name: 'Services', link: '/services' },
        { name: 'Residential Program', link: '/services/Residential-Program' },
        { name: 'Contact', link: './Contact' },
        { name: 'Testimonials', link: '/Testimonials' },
        { name: 'Community Partnerships', link: '/Community-Partnerships' },
        {
            name: 'Patient Portal',
            link: 'https://patientonlineportal.com/idp/account/signin',
            isExternal: true
        }
    ];

    // Flatten menu items for mobile view
    const flattenedMobileItems = menuItems.reduce((acc, item) => {
        if (item.submenu) {
            return [...acc, ...item.submenu];
        }
        return [...acc, item];
    }, []);

    return (
        <nav className="nav-container">
            <div className="nav-content">
                <Link to="/" className="logo">
                    <img src={logo} alt="Inroads to Recovery" />
                </Link>

                {/* Desktop Navigation */}
                <div className="desktop-menu">
                    {menuItems.map((item, index) => (
                        <div key={index} className="menu-item">
                            {item.submenu ? (
                                <>
                                    <button
                                        className="nav-link"
                                        onClick={() => setActiveDropdown(activeDropdown === index ? null : index)}
                                    >
                                        {item.name}
                                    </button>
                                    {activeDropdown === index && (
                                        <DropdownMenu
                                            items={item.submenu}
                                            isOpen={activeDropdown === index}
                                            setIsOpen={(open) => setActiveDropdown(open ? index : null)}
                                        />
                                    )}
                                </>
                            ) : (
                                item.isExternal ? (
                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="nav-link"
                                    >
                                        {item.name}
                                    </a>
                                ) : (
                                    <Link to={item.link} className="nav-link">
                                        {item.name}
                                    </Link>
                                )
                            )}
                        </div>
                    ))}
                </div>

                {/* Mobile Menu Button */}
                <button
                    className="mobile-menu-button"
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                    ☰
                </button>

                {/* Mobile Navigation - Flattened */}
                <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
                    {flattenedMobileItems.map((item, index) => (
                        <div key={index} className="mobile-menu-item">
                            {item.isExternal ? (
                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="mobile-nav-link"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    {item.name}
                                </a>
                            ) : (
                                <Link
                                    to={item.link}
                                    className="mobile-nav-link"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    {item.name}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </nav>
    );
};

export default Nav;
